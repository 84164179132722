import React from 'react'
import { Auth, Hub, Logger } from 'aws-amplify'
import { AmplifyButton, AmplifySignIn } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT
} from '@aws-amplify/ui-components'

const logger = new Logger('CustomSignIn')
const CustomSignIn = ({
  hideSignUp,
  federatedProvider,
  corporateIdEnabled = true,
  userPoolSignInEnabled = true,
  handleAuthStateChange = (nextAuthState, authData) => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: nextAuthState,
      data: authData
    })
  }
}) => {
  const corporateIdSignInButton = () => {
    if (corporateIdEnabled) {
      return (
        <AmplifyButton
          onClick={() =>
            Auth.federatedSignIn({
              provider:
                federatedProvider ||
                process.env.REACT_APP_OAUTH_AAD_PROVIDER_NAME
            })
          }
        >
          {' Corporate ID '}
        </AmplifyButton>
      )
    }
    return null
  }
  const authStateChangeHandler = (authState, authData) => {
    let nextAuthState = authState
    if (authState === AuthState.CustomConfirmSignIn) {
      logger.debug(
        'Custom challenge required (most likely EMAIL_MFA), so dispatching ConfirmSignIn event'
      )
      nextAuthState = AuthState.ConfirmSignIn
    }
    logger.debug('handling auth state change... ', {
      original: authState,
      nextAuthState,
      authData
    })
    handleAuthStateChange(nextAuthState, authData)
  }

  if (!userPoolSignInEnabled) {
    return (
      <amplify-form-section>
        <div className='form-section-header'>
          <h3 className='header' data-test='sign-in-header-section'>
            Sign in to your account
          </h3>
          <div slot='federated-buttons'>{corporateIdSignInButton()}</div>
        </div>
        <div slot='amplify-form-section-footer' />
      </amplify-form-section>
    )
  }

  return (
    <AmplifySignIn
      hideSignUp={hideSignUp}
      federated={corporateIdEnabled && userPoolSignInEnabled ? [{}] : {}}
      handleAuthStateChange={authStateChangeHandler}
      formFields={[
        {
          type: 'username',
          handleInputChange: (event, cb) => {
            event.target.value = event.target.value.toLowerCase()
            cb(event)
          }
        },
        {
          type: 'password'
        }
      ]}
    >
      <div slot='federated-buttons'>{corporateIdSignInButton()}</div>
    </AmplifySignIn>
  )
}
export default CustomSignIn
