import React from 'react'

import styles from './ErrorPage.module.scss'

const errorPage = ({ title, subTitle }) => (
  <div className={styles.errorPage}>
    <h1>{title}</h1>
    <p>{subTitle}</p>
  </div>
)

export default errorPage
