import React from 'react';
import PropTypes from 'prop-types';

import './common.scss';
import './DropDown.scss';

class DropDown extends React.Component {
    static propTypes = {
        handleChange: PropTypes.func.isRequired,
        labelName: PropTypes.string,
        id: PropTypes.string,
        placeHolderText: PropTypes.string, 
        errorMsg: PropTypes.string, 
        isDisabled: PropTypes.bool,
        options: PropTypes.object.isRequired,
        value: PropTypes.string,
        placeHolderDisabled: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.value ?? ''
        }

        this.handleChange = this.handleChange.bind(this);
      }

      componentDidUpdate = (prevProps) => {
        if (this.props.value != prevProps.value) this.setState({ selectedOption: this.props.value  });
      }

      handleChange = (e) => {
          const selectedOption = e.target.value;
          this.props.handleChange(e);
          this.setState({ selectedOption });
      }

      render() {
        const { labelName, id, placeHolderText, placeHolderDisabled = false, options, errorMsg, isDisabled = false } = this.props;

        const label = labelName
            ? <label className="wf-form-element-label" htmlFor={ id }>{labelName}:</label>
            : null;

        const defaultOption = placeHolderText 
            ? <option disabled={placeHolderDisabled} value="">{placeHolderText}</option>
            : null;

        const optionItems = Object.entries(options).map(([ key, value ]) => {
            return (
                <option key={ key } value={ key }>{value}</option>
            )
        });

        const className = errorMsg ? 'wf-select error' : 'wf-select';
          
        const error = errorMsg 
            ? <div className="error-msg">{errorMsg}</div>
            : null;

        return (
            <div className="wf-form-element-container">
                {label}
                <select disabled={ isDisabled } id={ id } name={ id } value={ this.state.selectedOption } onChange={ this.handleChange } className={ className }>
                    {defaultOption}  
                    {optionItems}
                </select>
                {error}
            </div>
        )
      }
}

export default DropDown;