import React from 'react'
import { Hub, Logger } from 'aws-amplify'
import { AmplifyVerifyContact } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT
} from '@aws-amplify/ui-components'

const logger = new Logger('CustomVerifyContact.js')
const shouldSkipVerification = (authData, groupToSkipVerification) =>
  authData &&
  authData.signInUserSession &&
  authData.signInUserSession.idToken.payload['cognito:groups'] &&
  authData.signInUserSession.idToken.payload['cognito:groups'].includes(
    groupToSkipVerification
  )

const CustomVerifyContact = ({
  authState,
  authData,
  groupToSkipVerification
}) => {
  React.useEffect(() => {
    logger.debug('customVerifyContact on onAuthUIStateChange...', {
      authState,
      authData
    })
    if (authState === AuthState.VerifyContact) {
      if (shouldSkipVerification(authData, groupToSkipVerification)) {
        // remove unverified property from authData
        logger.info('skipping contact information verification....')
        const newAuthData = { ...authData }
        delete newAuthData.unverified
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: AUTH_STATE_CHANGE_EVENT,
          message: AuthState.SignedIn,
          data: newAuthData
        })
      }
    }
  }, [authState, authData, groupToSkipVerification])

  // Skip contact verification for users within the configured group
  // keep contact verification for all other users.
  if (
    authState !== AuthState.VerifyContact ||
    shouldSkipVerification(authData, groupToSkipVerification)
  ) {
    return null
  }
  logger.debug('AmplifyVerifyContact Auth Data:', authData)
  return (
    <AmplifyVerifyContact headerText='Account recovery requires verified contact information' />
  )
}
export default CustomVerifyContact
