import React from 'react'
import { Auth, Logger, Hub } from 'aws-amplify'
import { AmplifyConfirmSignIn } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UI_AUTH_CHANNEL, ChallengeName } from '@aws-amplify/ui-components'

const logger = new Logger('CustomConfirmSignIn.js')

const CustomConfirmSignIn = ({ authState, authData }) => {
  const [code, setCode] = React.useState()
  const [isLoading, setLoading] = React.useState(false)

  const handleSubmit = (e) => {
    logger.debug('Verification code:', code)

    if (e) {
      e.preventDefault()
    }

    if (isLoading) {
      return
    }

    // if custom challenge, then answer using this api
    if (authData.challengeName === ChallengeName.CustomChallenge) {
      if (!code || code.length < 6) {
        return Hub.dispatch(UI_AUTH_CHANNEL, {
          event: 'ToastAuthError',
          message: 'Verification code can not be empty or less than 6'
        })
      }
      setLoading(true)
      Auth.sendCustomChallengeAnswer(authData, code, null)
        .then(() => {
          logger.info('Custom challenge succeeded')
          window.location.reload()
        })
        .catch((err) => {
          logger.error(err)
          Hub.dispatch(UI_AUTH_CHANNEL, {
            event: 'ToastAuthError',
            message:
              'Unable to verify code, please sign in again to get a new code'
          })
        })
        .finally(() => setLoading(false))
    } else {
      const mfaType =
        authData.challengeName === ChallengeName.SoftwareTokenMFA
          ? ChallengeName.SoftwareTokenMFA
          : null

      setLoading(true)
      Auth.confirmSignIn(authData, code, mfaType)
        .then(() => {
          logger.info('Standard challenge succeeded')
          window.location.reload()
        })
        .catch((err) => {
          logger.error(err)
          Hub.dispatch(UI_AUTH_CHANNEL, {
            event: 'ToastAuthError',
            message:
              'Unable to verify code, please sign in again to get a new code'
          })
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <AmplifyConfirmSignIn
      authData={authData}
      authState={authState}
      headerText='Please enter verification code sent to your email'
      handleSubmit={handleSubmit}
      formFields={[
        {
          type: 'code',
          required: true,
          handleInputChange: (e) => setCode(e.target.value)
        }
      ]}
      data-testid='amplify-custom-confirm-sign-in'
    />
  )
}
export default CustomConfirmSignIn
