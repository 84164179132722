import React from 'react';
import PropTypes from 'prop-types';

import './common.scss';
import './SearchInput.scss';

class SearchInput extends React.Component {
    static propTypes = {
        handleChange: PropTypes.func.isRequired,
        clearSearch: PropTypes.func.isRequired,
        labelName: PropTypes.string,
        id: PropTypes.string,
        placeHolderText: PropTypes.string
    }

    constructor(props) {
        super(props);
        this.state = {
            text: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
      }

      handleChange = (e) => {
          const text = e.target.value;
          this.props.handleChange(e);
          this.setState({ text });
      }

      handleFocus = (e) => e.target.select();

      clearSearch = () => {
          this.props.clearSearch();
          this.setState({ text:'' });
      }

      render() {
        const { labelName, id, placeHolderText } = this.props;

        const label = labelName
        ? <label className="wf-form-element-label" htmlFor={ id }>{labelName}:</label>
        : null;

        return (
            <div className="search-container">
                <div className="wf-form-element-container">
                    {label}
                    <input data-inputid={ id } className="wf-input search" name={ id } type="text" onFocus={ this.handleFocus } onChange={ this.handleChange } value={ this.state.text } placeholder={ placeHolderText }></input>
                </div>
                { this.state.text.length === 0
                ? (
                    <div className="search-icon spyglass"></div>
                )
                : (
                    <div className="search-icon clear" onClick={ this.clearSearch }>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" space="preserve">
                            <g><path fill="#004E36" d="M945.7,989.4L10,53.8L54.3,9.4L990,945.1L945.7,989.4z"/><path fill="#004E36" d="M10,946.2L945.7,10.6L990,54.9L54.3,990.6L10,946.2L10,946.2z"/></g>
                        </svg>
                    </div>
                )}
            </div>
        )
      }
}

export default SearchInput;