import React from 'react';
import PropTypes from 'prop-types';

import './ExternalTextLink.scss';

const ExternalTextLink = (props) => {
    const { name, href } = props;

    return (
        <a className="external-text-link" href={ href } target={ '_blank' } rel="noopener noreferrer">
            <span>{name}</span>
        </a>
    );
}

ExternalTextLink.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
}

export default ExternalTextLink;