import React from 'react';
import PropTypes from 'prop-types';

import './SecondaryButton.scss';

const SecondaryButton = (props) => {
    const { name, href } = props;

    const testid = name.replace(/\s/g, '').toLowerCase();

    return (
        <a data-testid={`testid-${testid}`} className="secondary-button" onClick={ props.onClick } href={ href } target={ '_self' }>
            <span>{name}</span>
        </a>
    );
}

SecondaryButton.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func.isRequired
}

export default SecondaryButton;