import React from 'react';
import PropTypes from 'prop-types';

import './RadioButton.scss';

class RadioButtons extends React.Component {
    static propTypes = {
      handleClick: PropTypes.func.isRequired,
      options: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: ''
        }

        this.handleClick = this.handleClick.bind(this);
      }

      handleClick = (e) => {
          const selectedOption = e.target.value;
          this.props.handleClick(selectedOption);
          this.setState({ selectedOption: selectedOption });
      }
    
      render() {
        const { options } = this.props;

        const optionItems = Object.entries(options).map(([ key, value ]) => {
            return (
                <div className="radio-button-container" key={ key }>
                    <label className="body-short-form">
                        <input type="radio" value={ key } checked={ this.state.selectedOption === key } onChange={ this.handleClick } />
                        <span></span>
                        {value}
                    </label>
                </div>
            )
        });

        return (
            <div>
                {optionItems}  
            </div>
        );
      }
}

export default RadioButtons;