import React, { Fragment } from 'react'
import { Hub, Logger } from 'aws-amplify'
import { AmplifyForgotPassword } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT
} from '@aws-amplify/ui-components'
import styles from './CustomForgotPassword.module.scss'

const logger = new Logger('CustomForgotPassword')
const CustomForgotPassword = ({
  handleAuthStateChange = (nextAuthState, authData) => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: nextAuthState,
      data: authData
    })
  }
}) => {
  const authStateChangeHandler = (authState, authData) => {
    const nextAuthState = authState
    logger.debug('handling auth state change... ', {
      original: authState,
      nextAuthState,
      authData
    })
    handleAuthStateChange(nextAuthState, authData)
  }

  // Amplify bug fixes
  Hub.listen(UI_AUTH_CHANNEL, ({ payload: { event, data, message } }) => {
    if (message === 'forgotpassword') {
      // Find password reset form in DOM
      const form = document
        .querySelector('amplify-authenticator')
        .querySelector('amplify-forgot-password').shadowRoot
      const formFields = form.querySelector('amplify-auth-fields').shadowRoot
      // Fix for Amplify bug that randomly displays two email form
      // inputs when using a custom password reset form. If we detect
      // two email fields, we delete the second one from the DOM.
      const emailFields = formFields.querySelectorAll('amplify-email-field')
      if (emailFields && emailFields.length > 1) {
        emailFields[1].remove()
        logger.debug('Amplify bug detected - removed duplicate email input')
      }
      // Fix for Amplify bug that doesn't display the reset password form
      // properly after the user submits a new password and verification
      // code. If we detect a validation code input and the submit button
      // text is Send Code, we reload the page to refresh the form.
      const codeField = formFields.querySelector('amplify-code-field')
      const buttonText = form
        .querySelector('amplify-form-section')
        .shadowRoot.querySelector('amplify-button').innerHTML
      if (codeField && buttonText.includes('Send Code')) {
        logger.debug('Amplify bug detected - refreshing reset password form')
        window.location.reload()
      }
    }
  })

  return (
    <Fragment>
      <AmplifyForgotPassword
        handleAuthStateChange={authStateChangeHandler}
        usernameAlias='email'
        formFields={[
          {
            type: 'email',
            required: true,
            handleInputChange: (event, cb) => {
              event.target.value = event.target.value.toLowerCase()
              cb(event)
            }
          }
        ]}
      />
      <div id={styles.customMessage}>
        Temporary passwords for new users expire after 30 days. If you have not
        logged in to confirm your account within that time, please contact WFM
        to request a new password.
      </div>
    </Fragment>
  )
}
export default CustomForgotPassword
