import React, { useRef, useState } from 'react'
import { Auth, Logger } from 'aws-amplify'
import IdleTimer from 'react-idle-timer'
import {
  PrimaryButton,
  SecondaryButton,
  Modal
} from 'wf-react-component-library'

import './IdleTimerContainer.scss'

const logger = new Logger('IdleTimerContainer.js')

const IdleTimerContainer = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false)
  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)

  const onIdle = () => {
    logger.debug('User is Idle')
    setIsModalOpen(true)
    sessionTimeoutRef.current = setTimeout(logOut, 2 * 60 * 1000)
  }

  const logOut = async () => {
    setIsModalOpen(false)
    clearTimeout(sessionTimeoutRef.current)
    logger.debug('User is logged out')
    await Auth.signOut()
  }

  const stayActive = () => {
    setIsModalOpen(false)
    clearTimeout(sessionTimeoutRef.current)
    logger.debug('User is Active')
  }

  return (
    <div className='idle-modal'>
      <Modal show={modalIsOpen} width='50%'>
        <div
          className='flex-container-row idle-message'
          style={{ justifyContent: 'center' }}
        >
          You have been idle for a while and will be logged out soon.
        </div>
        <br />

        <div
          className='flex-container-row idle-buttons'
          style={{ justifyContent: 'center' }}
        >
          <PrimaryButton onClick={stayActive} name='Stay' />
          <SecondaryButton onClick={logOut} name='Logout' />
        </div>
      </Modal>
      <IdleTimer ref={idleTimerRef} timeout={58 * 60 * 1000} onIdle={onIdle} />
    </div>
  )
}

export default IdleTimerContainer
