import React from 'react';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';

import './common.scss';
import './MultiSelectDropDown.scss';

// see https://www.npmjs.com/package/multiselect-react-dropdown
class MultiSelectDropDown extends React.Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        labelName: PropTypes.string,
        id: PropTypes.string,
        placeHolderText: PropTypes.string, 
        errorMsg: PropTypes.string, 
        isDisabled: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
        value: PropTypes.string,
        displayValue: PropTypes.string.isRequired,
        selectedValues: PropTypes.arrayOf(PropTypes.object)
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: []
        }

        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onSelect = (selectedList, selectedItem) => {
        this.props.onSelect(selectedList, selectedItem);
    }

    onRemove = (selectedList, removedItem) => {
        this.props.onRemove(selectedList, removedItem);
    }

    render() {
        const { labelName, id, options, displayValue, errorMsg, isDisabled = false, selectedValues = [], placeHolderText = '' } = this.props;

        const label = labelName
            ? <label className="wf-form-element-label" htmlFor={ id }>{labelName}:</label>
            : null;

        const className = errorMsg ? 'wf-select error' : 'wf-select';
            
        const error = errorMsg 
            ? <div className="error-msg">{errorMsg}</div>
            : null;

        return (
            <div id={ id } className="wf-form-element-container">
                {label}
                <Multiselect
                        options={ options } // Options to display in the dropdown
                        selectedValues={ selectedValues } // Preselected value to persist in dropdown
                        onSelect={ this.onSelect } // Function will trigger on select event
                        onRemove={ this.onRemove } // Function will trigger on remove event
                        displayValue={ displayValue } // Property name to display in the dropdown options
                        disable={ isDisabled }
                        placeholder={ placeHolderText }
                    />
                {error}
            </div>
        )
    }
}

export default MultiSelectDropDown;