import React, { Component } from 'react'
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthState } from '@aws-amplify/ui-components'

import { Logger } from 'aws-amplify'
import CustomSignIn from '../../CustomSignIn/CustomSignIn'
import CustomConfirmSignIn from '../../CustomConfirmSignIn/CustomConfirmSignIn'
import CustomForgotPassword from '../../CustomForgotPassword/CustomForgotPassword'
import CustomVerifyContact from '../../CustomVerifyContact/CustomVerifyContact'
import ErrorPage from '../../../ErrorPage/ErrorPage'
import StageBadge from '../../../StageBadge/StageBadge'
import IdleTimerContainer from '../../../timeout/IdleTimerContainer'

import styles from './SecuredVendorApp.module.scss'

const logger = new Logger('SecuredVendorApp.js')

class SecuredVendorApp extends Component {
  authStateChangeHandler = (nextAuthState, authData) => {
    logger.debug('AuthState has changed to:', { nextAuthState })
    this.setState({ authState: nextAuthState, user: authData })
  }

  render() {
    const { authState, user } = { ...this.state }
    const {
      corporateIdEnabled = true,
      userPoolSignInEnabled = true,
      federatedProvider,
      groupToSkipVerification
    } = this.props
    const appContent = () => {
      if (authState === AuthState.SignedIn && user) {
        const appGroups = [...this.props.requiredCognitoGroups]
        logger.debug('required groups for this app', appGroups)
        logger.debug('signed in user', user)
        const userGroups =
          user.signInUserSession.idToken.payload['cognito:groups']
        if (userGroups) {
          logger.debug('user groups', userGroups)
          const userAllowedGroups = userGroups.filter((ug) =>
            appGroups.includes(ug)
          )
          if (userAllowedGroups.length > 0) {
            logger.debug('user has these allowed groups', userAllowedGroups)
            return this.props.children
          }
        }
        return (
          <ErrorPage
            data-testid='access-denied'
            title='Access Denied'
            subTitle="You don't have the required permissions to access this application, please contact the system administrators"
          />
        )
      }
      return null
    }

    return (
      <div className={styles.app}>
        <AmplifyAuthenticator
          data-testid='amplify-authenticator'
          usernameAlias='email'
          handleAuthStateChange={this.authStateChangeHandler}
          className={
            authState === AuthState.SignedIn && user
              ? 'SignedIn'
              : 'NotSignedIn'
          }
        >
          <div slot='sign-in' data-testid='auth-custom-sign-in'>
            <CustomSignIn
              hideSignUp
              corporateIdEnabled={corporateIdEnabled}
              userPoolSignInEnabled={userPoolSignInEnabled}
              federatedProvider={federatedProvider}
            />
          </div>

          <div slot='confirm-sign-in' data-testid='auth-custom-confirm-sign-in'>
            <CustomConfirmSignIn
              authData={user}
              authState={authState}
              slot='confirm-sign-in'
            />
          </div>
          {groupToSkipVerification ? (
            <div slot='verify-contact' data-testid='auth-custom-verify-contact'>
              <CustomVerifyContact
                authData={user}
                authState={authState}
                groupToSkipVerification={groupToSkipVerification}
              />
            </div>
          ) : null}
          <div slot='forgot-password' data-testid='auth-custom-forgot-password'>
            <CustomForgotPassword />
          </div>

          <div className={`${styles.pageHeader} flex-container-row`}>
            <div className='header-title flex-container-column'>
              <div className='flex-container-row'>
                <div className='headline-serif' data-testid='vma-app-name'>
                  {this.props.appName} <StageBadge />
                </div>
              </div>
            </div>
            <div className='header-title flex-container-column'>
              {authState === AuthState.SignedIn && user ? (
                <div
                  className='flex-container-row'
                  data-testid='vma-app-greeting'
                >
                  <span className={`${styles.bodyShortForm} label`}>
                    Hello,{' '}
                  </span>
                  <span className={styles.bodyShortForm}>
                    {user.signInUserSession.idToken.payload.name ||
                      user.signInUserSession.idToken.payload.email}
                  </span>
                  <div
                    style={{ marginLeft: '10px' }}
                    data-testid='amplify-sign-out'
                  >
                    <AmplifySignOut
                      handleAuthStateChange={this.authStateChangeHandler}
                    />
                  </div>
                  <div>
                    <IdleTimerContainer />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {appContent()}
        </AmplifyAuthenticator>
      </div>
    )
  }
}

export default SecuredVendorApp
