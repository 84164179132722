import React from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

class Modal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    width: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
  }

  render() {
    if(!this.props.show) {
        return null;
    }

    return (
        <div>
            <div className="overlay"></div>
            <div className="modal" style={ { width: this.props.width } }>
                {this.props.children}
            </div>
        </div>
    )
  }
}

export default Modal;