import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './NavBar.scss';

class NavBar extends React.Component{ 
    static propTypes = {
        landingPage: PropTypes.string.isRequired,
        location: PropTypes.object,
        navItems: PropTypes.object.isRequired
    }  

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: ''
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount = () => {       
        // defaults to the landing page if the route is /
        if (this.state.selectedOption === '') {
            this.setState({
                selectedOption: this.props.landingPage
            });
        }

        const { pathname } = this.props.location;

        // handles when user types in the url instead of using the
        // navigation
        if (pathname.length > 2) {
            const pathKey = pathname.substr(1, pathname.length);
            this.setState({
                selectedOption: this.props.navItems[ pathKey ]
            });
        }
    }  

    handleClick = (e) => {
        const selectedOption = e.target.innerText;
        this.setState({ selectedOption });
    }

    render() {
        const { navItems } = this.props;

        const nav = Object.entries(navItems).map(([ key, value ]) => {
            const item = value === this.state.selectedOption 
            ? <Link onClick={ this.handleClick } className="active"  to={ `/${ key }` }>{value}</Link> 
            : <Link onClick={ this.handleClick } to={ `/${ key }` }>{value}</Link>;
            
            return (
                <div className="nav-link" key={ key }>
                    {item}
                </div>
            )
        });

        return (
            <nav className='nav-wrapper'>
                <div className='nav-container'>
                    <div className='nav-links'>
                        {nav}
                    </div>
                </div>
            </nav>
        )
    }
}

export default withRouter(NavBar);