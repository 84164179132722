import React from 'react';
import PropTypes from 'prop-types';

import './CheckBox.scss';

class CheckBoxes extends React.Component {
    static propTypes = {
      handleClick: PropTypes.func.isRequired,
      options: PropTypes.object.isRequired,
      selectAll: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: []
        }

        this.handleClick = this.handleClick.bind(this);
      }

      handleClick = (e) => {
          const selectedOption = e.target.value;
          var selectedOptions = this.state.selectedOptions;

          if (this.state.selectedOptions.includes(selectedOption)) {
            //remove from the array
            selectedOptions = selectedOptions.filter(item => {
              return item !== selectedOption;
            })
          } else {
            selectedOptions.push(selectedOption)
          }

          this.props.handleClick(selectedOptions);

          this.setState({ selectedOptions });
      }
    
      render() {
        const { options, selectAll } = this.props;

        const optionItems = Object.entries(options).map(([ key, value ]) => {
            return (
                <div className="checkbox-container" key={ key }>
                    <label className="body-short-form">
                        <input type="checkbox" value={ key } checked={ selectAll || this.state.selectedOptions.includes(key) } onChange={ this.handleClick } />
                        <span></span>
                        {value}
                    </label>
                </div>
            )
        });

        return (
            <div>
                {optionItems}  
            </div>
        );
      }
}

export default CheckBoxes;