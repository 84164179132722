import React from 'react';
import PropTypes from 'prop-types';

import './common.scss';
import './TextInput.scss';

class TextInput extends React.Component {
    static propTypes = {
      handleChange: PropTypes.func.isRequired,
      labelName: PropTypes.string,
      id: PropTypes.string,
      placeHolderText: PropTypes.string, 
      errorMsg: PropTypes.string, 
      isDisabled: PropTypes.bool,
      value: PropTypes.string
    }

    constructor(props) {
      super(props);
      this.state = {
          text: this.props.value ?? ''
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
    }

    componentDidUpdate = (prevProps) => {
      if (this.props.value != prevProps.value) this.setState({text: this.props.value  });
    }

    handleChange = (e) => {
        const text = e.target.value;
        this.props.handleChange(e);
        this.setState({ text });
    }

    handleFocus = (e) => e.target.select();

    render() {
        const { labelName, id, placeHolderText, errorMsg, isDisabled = false } = this.props

        const className = errorMsg ? 'wf-input error' : 'wf-input';
        
        const error = errorMsg 
          ? <div className="error-msg">{errorMsg}</div>
          : null;

        const label = labelName
          ? <label className="wf-form-element-label" htmlFor={ id }>{labelName}:</label>
          : null;

        return (
            <div className="wf-form-element-container">
                {label}
                <input data-testid={ id } data-inputid={ id } disabled={ isDisabled } className={ className } name={ id } type="text" onFocus={ this.handleFocus } onChange={ this.handleChange } value={ this.state.text } placeholder={ placeHolderText }></input>
                {error}
            </div>
          )
    }
}

export default TextInput;