import React from 'react'
import styles from './StageBadge.module.scss'

const stageBadge = ({
  stageName = process.env.REACT_APP_STAGE || 'default',
  ignoredStages = ['prd', 'prod', 'production']
}) => {
  const stageNameClass = `stage-${stageName.toLowerCase()}`

  if (ignoredStages.includes(stageName)) {
    return null
  }
  return (
    <div
      data-testid='wfm-stage-badge'
      className={`${styles.stageBadge} ${
        styles[stageNameClass] ? styles[stageNameClass] : 'stage-default'
      }`}
    >
      <span>{stageName.toUpperCase()}</span>
    </div>
  )
}

export default stageBadge
