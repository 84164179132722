import React from 'react';
import PropTypes from 'prop-types';

import './Tooltip.scss';

const Tooltip = (props) =>  {
    const { tip, tooltip, tooltipClassName } = props;

    return (
        <div className="tooltip-container">
            <span className="tooltip-text" onMouseLeave={ props.onMouseLeave } onMouseEnter={ props.onMouseEnter }>
                {tip}
                <div className={ tooltipClassName }>{tooltip}</div>
            </span>
        </div>
    );
}

Tooltip.propTypes = {
    tooltipClassName: PropTypes.string,
    tooltip: PropTypes.string.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    tip: PropTypes.string.isRequired
}

export default Tooltip;