import React from 'react';
import PropTypes from 'prop-types';

import './PrimaryButton.scss';

const PrimaryButton = (props) => {
    const { name, href } = props;

    const testid = name.replace(/\s/g, '').toLowerCase();

    return (
        <a  data-testid={`testid-${testid}`} className="primary-button" onClick={ props.onClick } href={ href } target={ '_self' }>
            <span>{name}</span>
        </a>
    );
}

PrimaryButton.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.func,
    onClick: PropTypes.func.isRequired
}

export default PrimaryButton;