import React from 'react';
import PropTypes from 'prop-types';

import './ExternalButton.scss';

const ExternalButton = (props) => {
    const { name, href } = props;

    const testid = name.replace(/\s/g, '').toLowerCase();

    return (
        <a data-testid={`testid-${testid}`} className="external-button" href={ href } target={ '_blank' } rel="noopener noreferrer">
            <span>{name}</span>
        </a>
    );
}

ExternalButton.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
}

export default ExternalButton;